<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo">
      <b-img
         style="width: 150px"
        :src="appDarkLogo"
        alt="logo"
      />
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3" v-if="company">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Your {{company.company_name}} Company Account is Activated
        </h2>
        <p class="mb-2 mt-2">
          Your Account is Registered and we are in process of creating a sub-domain for your organisation.Once the process is completed we will send you an email with Login Credentials.
        </p>
        <p class="mb-2" >Soon you can access Zircly Admin on Domain : {{company.domain}}
        </p>
        <p class="mb-3">
          If you need to discuss pricing and plans please contact us <b-link >{{support}}</b-link> .
        </p>
        

        <!-- form -->
        <!-- <b-form
          inline
          class="row justify-content-center m-0 mb-2"
          @submit.prevent
        >
          <b-form-input
            id="notify-email"
            class="col-12 col-md-5 mb-1 mr-md-2"
            type="text"
            placeholder="john@example.com"
          />

          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            type="submit"
          >
            Notify
          </b-button>
        </b-form> -->

        <b-img
          fluid
          :src="imgUrl"
          alt="Activated"
        />
      </div>
    </div>
    <div class="misc-inner p-2 p-sm-3" v-else>
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Wait a Second We are Activating Your Company Account.
        </h2>
        <p class="mb-3">
          Your Account is Registered and we are in process of creating a sub-domain for your organisation.Once the process is completed we will send you an email with Login Credentials.
        </p>
        <p class="mb-3">
          If you need to discuss pricing and plans please contact us <b-link >{{support}}</b-link>
        </p>

        <!-- form -->
        <!-- <b-form
          inline
          class="row justify-content-center m-0 mb-2"
          @submit.prevent
        >
          <b-form-input
            id="notify-email"
            class="col-12 col-md-5 mb-1 mr-md-2"
            type="text"
            placeholder="john@example.com"
          />

          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            type="submit"
          >
            Notify
          </b-button>
        </b-form> -->

        <b-img
          fluid
          :src="imgUrl"
          alt="Activated"
        />
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BForm, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import axios from "@axios";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/annoucement.png'),
      support: process.env.VUE_APP_SUPPORT_EMAIL,
      company: null,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/annoucement.png')
        return this.downImg
      }
      return this.downImg
    },
  },
  created(){
    axios
        .get("/activate/"+this.$route.params.token)
        .then((response) => {
          if(response.data.success){
            this.company = response.data.data;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Company Account Activated',
            //     icon: 'EditIcon',
            //     variant: 'success',
            //     text:response.data.message
            //   },
            // })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Company Account Activated',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Activation Failed',
            //     icon: 'AlertTriangleIcon',
            //     variant: 'danger',
            //     text:response.data.message
            //   },
            // })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Activation Failed',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   props: {
          //     title: "Activation Failed",
          //     icon: "AlertTriangleIcon",
          //     variant: "danger",
          //     text: "Please try again after sometime",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Activation Failed',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: "Please try again after sometime",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app
    return {
      appName,
      appDarkLogo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>